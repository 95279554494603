import './shared';
import './modules/front';
import React from 'react';
import ReactDOM from 'react-dom';
import ItemDatePicker from './modules/front/ItemDatePicker';

// Bootstrap
import './modules/front/scss/main.scss'
//import 'bootstrap/scss/bootstrap.scss';


// Webpack HMR

if (module.hot) {
	module.hot.accept();
}

const e = React.createElement;
const domContainer = document.querySelector('#item_date_picker_container');
const root = ReactDOM.createRoot(domContainer);
root.render(e(ItemDatePicker, {'locale': domContainer.getAttribute('data-locale')}));

